
"use strict";

const btn = document.querySelector('.darkmode');
let activeMode = localStorage.getItem("mode");
if (activeMode == "dark") {
	document.body.classList.add("dark");
}
btn.addEventListener('click', () => {
	document.body.classList.toggle("dark");
	if (document.body.classList.contains("dark")) {
		localStorage.setItem("mode", "dark");
       
	} else {
		localStorage.setItem("mode", "light");
	}
});

